import Layout from "../common/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="container">
        <h2 className="section-title p0 my-4 mx-2">
          <span>Privacy Policy</span>
        </h2>
        <p>
          Our privacy policy explains who we are and what we do with your
          personal information. It applies to customers, website users and our
          suppliers. We may at any time make changes to our policy. Please visit
          our privacy policy page to stay up to date as we will post any changes
          here.
        </p>
        <p>
          <h2>Who We Are</h2>
        </p>
        <p>
          We are Coker Exhibition Systems Ltd, 7 Foster Business Park, Old
          School Road, Hook Hampshire GU14 9XD Registered Company number
          04605344
        </p>
        <p>
          <h2>Information We Collect</h2>
        </p>
        <p>
          For relationships to run smoothly with customer and suppliers we need
          to collect and store personal information. This information includes:
        </p>
        <p>
          <ul></ul>
        </p>
        <p>
          <li>Name</li>
        </p>
        <p>
          <li>Job title</li>
        </p>
        <p>
          <li>Company name</li>
        </p>
        <p>
          <li>Postal address</li>
        </p>
        <p>
          <li>Email address</li>
        </p>
        <p>
          <li>Telephone number</li>
        </p>
        <p>
          <li>Payment details</li>
        </p>
        <p>
          <li>VAT details (if appropriate)</li>
        </p>
        <p></p>
        <p>
          We need to collect and process these types of personal data to ensure
          the smooth running of our business relationship and provide you with
          the items you purchase from us, or to pay for services you may be
          supplying.
        </p>
        <p>
          <h2>Collection of Personal Information</h2>
        </p>
        <p>We collect personal information in these ways:</p>
        <p>When you fill in and submit our contact form on our website</p>
        <p>When you place an order with us</p>
        <p>
          When you freely give your details to us either via telephone, face to
          face at events or via email
        </p>
        <p>When we agree to use your services</p>
        <p>
          When we use a search engine to find an appropriate supplier for our
          business needs
        </p>
        <p>Use of Personal Information</p>
        <p>
          We use your personal information for legitimate business interests in
          a number of ways, including the following:
        </p>
        <p>
          <h2>To fulfil your order/s</h2>
        </p>
        <p>To provide quality customer service</p>
        <p>
          We may, from time to time, send you promotional materials to the email
          address you have provided. You may be removed from this list at any
          time please advise
        </p>
        <p>
          We promise will never share, sell or distribute your personal
          information to a third party unless a lawful obligation requires us
          to.
        </p>
        <p>
          <h2>Other Information We Collect</h2>
        </p>
        <p>
          "Other Information" is any information that does not reveal your
          specific identity or does not directly relate to an identifiable
          individual, such as:
        </p>
        <p>
          <h2>Browser and device information</h2>
        </p>
        <p>
          Information collected through cookies, pixel tags and other
          technologies
        </p>
        <p>
          Demographic information and other information provided by you that
          does not reveal your specific identity
        </p>
        <p>
          Information that has been aggregated in a manner such that it no
          longer reveals your specific identity
        </p>
        <p>Protecting Information</p>
        <p>
          Protecting your personal information is important to us. We do not
          sell, share, rent or lease personal information. We have in place
          organisational, technical and administrative measures designed to
          protect personal information within our organisation. In the unlikely
          event you feel your information is not secure please contact us
          through the contact form on our website
        </p>
        <p>
          We will only ever disclose personal information if it is required by
          law, such as evidence for criminal proceedings.
        </p>
        <p>
          <h2>Marketing &amp; Communication</h2>
        </p>
        <p>
          We send communication(s) to our customers in relation to their sales
          orders, this is in multiple ways including, but not limited to, email
          communication and telephone communication. We will ask you first
          before sending you specific marketing emails and you can unsubscribe
          to our mailing list at any time.
        </p>
        <p>How You Can Access, Change or Suppress Your Personal Information</p>
        <p>
          If you would like to request to review, correct, update, suppress or
          delete Personal Information that you have previously provided to us,
          or if you would like to request to receive an electronic copy of your
          Personal Information for purposes of transmitting it to another
          company (to the extent this right to data portability is provided to
          you by applicable law), you may make this request by visiting our
          feedback@cokerexpo.co.uk. We will respond to your request consistent
          with applicable law.
        </p>
        <p>
          In your request, please make clear what Personal Information you would
          like to have changed, whether you would like to have your Personal
          Information suppressed from our database or otherwise let us know what
          limitations you would like to put on our use of your Personal
          Information. For your protection, we will only implement requests with
          respect to the Personal Information associated with the particular
          email address that you use to send us your request, and we may need to
          verify your identity before implementing your request. We will try to
          comply with your request as soon as reasonably practicable.
        </p>
        <p>
          Please note that we may need to retain certain information for
          recordkeeping purposes and/or to complete any transactions that you
          began prior to requesting a change or deletion. There may also be
          residual information that will remain within our databases and other
          records, which will not be removed unless asked to do so.
        </p>
        <p>
          <h2>Call recording</h2>
        </p>
        <p>
          Selected calls will be recorded for staff training or for quality
          control details and/verifying orders. These calls are stored for a
          maximum of 30 days.
        </p>
        <p>
          <h2>CCTCV</h2>
        </p>
        <p>
          There is CCTV on the premise for security and crime prevention. The
          videos will only ever be shared with a third party in the event of a
          crime that has been taken place and will only be stored for the
          maximum of 30 days unless the videos are required by law for criminal
          proceedings.
        </p>
        <p>
          <h2>How long do we keep your data</h2>
        </p>
        <p>
          We do not keep your data for longer than is necessary. We will delete
          your personal data from our systems unless we believe in good faith
          that the law or other regulation requires us to preserve it.
        </p>
        <p>
          <h2>Use of Website by Minors</h2>
        </p>
        <p>
          The products that we provide are not directed to individuals under the
          age of sixteen (16), and we do not knowingly collect Personal
          Information from individuals under sixteen (16).
        </p>
        <p>
          <h2>Updates to this Privacy Policy</h2>
        </p>
        <p>
          We may change this Privacy Policy. The "Last Updated" legend at the
          bottom of this Privacy Policy indicates when this Privacy Policy was
          last revised. Any changes will become effective when we post the
          revised Privacy Policy on the Services. Your use of the Services
          following these changes means that you accept the revised Privacy
          Policy.
        </p>
        <p>
          <h2>How to Speak to us About Data Protection</h2>
        </p>
        <p>You can contact us via our contact us page.</p>
        <p>
          <h2>Cookies</h2>
        </p>
        <p>
          A cookie is a string of information that a website stores on your
          computer, your browser then provides this information to our website
          each time you return.
        </p>
        <p>
          We use cookies to help us identify and track visitors, their usage of
          our website, and their website access preferences. This enables us to
          improve our website, offer you products we believe you may be
          interested in and provide a smooth customer experience.
        </p>
        <p>
          When you first visit our website you will be clearly notified that our
          site uses cookies and you will have the option to agree to have our
          cookies stored on your device or to not agree. If you agree to the
          cookies you will not see this message the next time you visit our
          website.
        </p>
        <p>
          If at any time you want to delete our cookies from your device, you
          will need to clear them from your devices cookie setting. Please refer
          to your device instructions to find how you do this. Certain features
          of our website may not function properly without the aid of cookies.
        </p>
        <p>For further information please see our Cookie Policy</p>
        <p>
          <h2>Cookie Policy</h2>
        </p>
        <p>
          A cookie is a string of information that a website stores on your
          computer, your browser then provides this information to our website
          each time you return
        </p>
        <p>
          We use cookies to help us identify and track visitors, their usage of
          our website, and their website access preferences. This enables us to
          improve our website, offer you products we believe you may be
          interested in and provide a smooth customer experience.
        </p>
        <p>
          When you first visit our website you will be clearly notified that our
          site uses cookies and you will have the option to agree to have our
          cookies stored on your device or to not agree. If you agree to the
          cookies you will not see this message the next time you visit our
          website.
        </p>
        <p>
          If at any time you want to delete our cookies from your device, you
          will need to clear them from your devices cookie setting. Please refer
          to your device instructions to find how you do this. Certain features
          of our website may not function properly without the aid of cookies.
        </p>
        <p>
          All Cookies used by our website are used in accordance with the
          provisions of the Privacy and Electronic Communications (EC Directive)
          Regulations 2003 as amended by the Privacy and Electronic
          Communications (EC Directive) (Amendment) Regulations 2011.
        </p>
        <p>
          Coker Exhibition Systems Ltd have carefully chosen these Cookies and
          use them to facilitate certain functions and features of the Web Site.
        </p>
        <p>
          We also use Cookies for analytics purposes. These Cookies track your
          movements and activities on the web site and are designed to give us a
          better understanding of our users, thus enabling us to improve the web
          site and our services.
        </p>
        <p>
          Certain features of our web site may depend upon Cookies to function
          and are deemed, within the law, to be strictly necessary. You will not
          be asked for your consent to place these Cookies however you may still
          disable cookies via your web browser’s settings.
        </p>
        <p>
          You can choose to enable or disable Cookies in your web browser. By
          default, your browser will accept Cookies, however, this can be
          altered. For further details please consult the help menu in your
          browser. Disabling Cookies may prevent you from using the full range
          of Services available on the web site.
        </p>
        <p>
          You may delete Cookies at any time; however, you may lose any
          information that enables you to access our website quickly.
        </p>
        <p>
          For more information about the cookies we use please contact us on
          +44 (0)1256 578 017
        </p>
      </div>
    </Layout>
  );
};
export default PrivacyPolicy;
